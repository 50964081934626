<template>
  <v-card :loading="loading">
    <v-system-bar>Feedbacks</v-system-bar>
    <v-data-table
      :headers="headers"
      :items="itemsFiltered"
      sort-by="date"
      :sort-desc="true"
    >
      <template v-slot:item.person="{ item }">
        <PersonItemBasic :value="item.person" />
      </template>
      <template v-slot:item.schoolClasses="{ item }">
        <div
          v-for="schoolClass in item.schoolClasses"
          :key="item.id + '_' + schoolClass.code"
        >
          <v-tooltip top v-if="schoolClass.percentage >= 10">
            <template v-slot:activator="{ on, attrs }">
              <v-chip small dark v-bind="attrs" v-on="on">
                <v-progress-circular
                  color="success"
                  size="16"
                  width="2"
                  :value="schoolClass.percentage"
                  class="mr-2"
                ></v-progress-circular>
                {{ schoolClass.code }}
              </v-chip>
            </template>
            <span>{{ schoolClass.code }}: {{ schoolClass.percentage }} %</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.date="{ item }">
        <DateValue :value="item.date" />
      </template>
      <template v-slot:item.closed="{ item }">
        <v-simple-checkbox :value="item.closed" disabled />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import { comparePeople, searchPerson } from "common/utils/people";
import DateValue from "common/components/DateValue.vue";
import PersonItemBasic from "common/components/PersonItemBasic.vue";
export default defineComponent({
  components: { DateValue, PersonItemBasic },
  props: ["search", "period"],
  data() {
    return {
      headers: [
        {
          text: "LK",
          value: "person",
          sort: comparePeople,
        },
        { text: "Methode", value: "method.description", sortable: false },
        { text: "Beschreibung", value: "description", sortable: false },
        { text: "Klassen", value: "schoolClasses", sortable: false },
        { text: "Datum", value: "date" },
        { text: "abgeschlossen", value: "closed", sortable: false },
      ],
      items: [],
      loading: false,
    };
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (item) =>
          !this.search ||
          searchPerson(item.person, this.search) ||
          (this.search &&
            item.schoolClasses &&
            item.schoolClasses.some((el) =>
              el.code.toLowerCase().includes(this.search.toLowerCase())
            ))
      );
    },
  },
  watch: {
    period() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.items = await this.apiList({
        resource: "quality/feedback",
        query: this.period ? "period=" + this.period.id : "",
      });
      this.loading = false;
    },
  },
  async created() {
    this.fetchData();
  },
});
</script>
